import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import tippy from 'tippy.js';
import * as map from 'lodash/map';
import { DashboardService } from '../../services/dashboard.service';
import { ConnectionService } from '../../services/connection.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements AfterViewInit, OnInit {
  
    titleField : string = 'News';
    news: any = [];
    changeCategoryShow: string = 'All categories';
    @ViewChild('changeCategory', {static: false }) changeCategory: ElementRef;
    destroy$: Subject<any> = new Subject();
    public feedType:any = [];
    public selFeedType = '';
    constructor(private LS:LogoutService,
      private router: Router,private titleService:Title,private dashboardService:DashboardService,private connectionService:ConnectionService,private loader:LoaderService,) {
        this.titleService.setTitle('News - MetSwift');
        this.loader.display(true);
        this.getNews();
    }
    ngOnInit(){
      this.LS.logOut({"title":"News","link":"#/news"})
        .subscribe(
        data => {},
        error => {
          this.router.navigate(['/login']);
          this.loader.display(false);
        }
        );
      this.connectionService.display(true);
      this.loader.display(false);
      this.getNews();

    }

    ngAfterViewInit(): void {
      let feeds = `<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category">All categories</button>`;
       this.feedType.map((f)=>{
        feeds += '<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category">'+f.itemName+'</button>'
      })
      
        const app = this
          if(this.changeCategory.nativeElement){
            tippy(this.changeCategory.nativeElement, {
                allowHTML: true,
                theme: 'light-border',
                placement: 'bottom',
                trigger: 'click',
                arrow: true,
                animation: 'shift-away',
                interactive: true,
                content: feeds,
                onMount(instance) {
                  const selector = instance.popper.querySelectorAll('.select-category')
                  if(selector){
                    map(selector, elem => {
                        elem.addEventListener('click', (e) => {
                          app.onChangeCategoryType(e.target, instance)
                        })
                    })
                  }

                },
            })
          }
    }
    onChangeCategoryType(changeTo: any, instance: any): void {
      this.changeCategoryShow = changeTo.textContent
      console.log(changeTo.id)
      this.selFeedType = changeTo.id;
      this.getNews()
      instance.hide()
    }

    getNews() {        
        if (navigator.onLine == true) {
            this.loader.display(true);
            let selefeed = [];
            if(this.selFeedType){
              selefeed = [this.selFeedType]
            }
            this.dashboardService.getNews({ "id": selefeed })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.loader.display(false);
                    let successData = data;
                    this.feedType = successData.feedType;
                    this.news = [];
                    if ( successData && successData.news ) {
                        let len = successData.news.length;
                        for ( let i=0;i<len;i++ ) {
                            //if ( i < 3  ) {
                                if(successData.news[i]){
                                  let newdata = {category: {link: '',title: '',},thumbnail: '',title: '',excerpt: '',link:''};
                                  newdata.category.title = successData.news[i].category;
                                  newdata.title = successData.news[i].title;
                                  newdata.link = successData.news[i].link;
                                  if ( newdata.category.title == 'Storms' ) {
                                      newdata.thumbnail = '/assets/images/hurricane.png';
                                      successData.news[i].description = successData.news[i].description.replace(/<dl[^>]*>/g,'').replace(/<\/dl>/g,' ');
                                      successData.news[i].description = successData.news[i].description.replace(/<dt[^>]*>/g,'').replace(/<\/dt>/g,' ');
                                      successData.news[i].description = successData.news[i].description.replace(/<dd[^>]*>/g,'').replace(/<\/dd>/g,' ');
                                      newdata.excerpt = successData.news[i].description.substring(0, 100);
                                  } else {

                                      if(parseFloat(successData.news[i].title.split(' ')[1]) >= 9){
                                        newdata.thumbnail = '/assets/images/m9.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 8 && parseFloat(successData.news[i].title.split(' ')[1]) < 9 ){
                                        newdata.thumbnail = '/assets/images/m8.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 7 && parseFloat(successData.news[i].title.split(' ')[1]) < 8){
                                        newdata.thumbnail = '/assets/images/m7.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 6 && parseFloat(successData.news[i].title.split(' ')[1]) < 7){
                                        newdata.thumbnail = '/assets/images/m6.png';
                                      } else {
                                        newdata.thumbnail = '/assets/images/m5.png';
                                      } 
                                      if(parseFloat(successData.news[i].title.split(' ')[1]) < 4){
                                        continue;
                                      }
                                      successData.news[i].description = successData.news[i].description.replace(/<dl[^>]*>/g,'').replace(/<\/dl>/g,' ');
                                      successData.news[i].description = successData.news[i].description.replace(/<dt[^>]*>/g,'').replace(/<\/dt>/g,' ');
                                      successData.news[i].description = successData.news[i].description.replace(/<dd[^>]*>/g,'').replace(/<\/dd>/g,' ');
                                      let newsContentArr = successData.news[i].description.split('epicenter');
                                      newsContentArr[0] = '<p>'+newsContentArr[0]+'epicenter.</p>';
                                      newsContentArr[1] = '<p>'+newsContentArr[1]+'</p>';
                                      successData.news[i].description = newsContentArr[0]+newsContentArr[1];
                                      successData.news[i].description = successData.news[i].description.replace('Depth','<br>Depth');
                                      newdata.excerpt = successData.news[i].description;
                                  }
                                  this.news.push(newdata);
                                }
                                
                            //}
                        }
                        console.log(this.changeCategory.nativeElement)
                        let feeds = `<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category" id="">All categories</button>`;
                        this.feedType.map((f)=>{
                          feeds += '<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category" id="'+f.id+'">'+f.itemName+'</button>'
                        })
                        
                          const app = this
                            if(this.changeCategory.nativeElement){
                              tippy(this.changeCategory.nativeElement, {
                                  allowHTML: true,
                                  theme: 'light-border',
                                  placement: 'bottom',
                                  trigger: 'click',
                                  arrow: true,
                                  animation: 'shift-away',
                                  interactive: true,
                                  content: feeds,
                                  onMount(instance) {
                                    const selector = instance.popper.querySelectorAll('.select-category')
                                    if(selector){
                                      map(selector, elem => {
                                          elem.addEventListener('click', (e) => {
                                            app.onChangeCategoryType(e.target, instance)
                                          })
                                      })
                                    }

                                  },
                              })
                            }
                    }
                    
                },
                error => {
                    this.loader.display(false);
                }
            )
        } else {
            this.connectionService.display(true);
            this.loader.display(false);
        }
    }

}
