    <div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-6 w-auto lg:w-1/2">
                    <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
                </div>
                <!--<div class="px-6 w-auto flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>-->
            </div>
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="page-content w-full lg:w-1/2 px-4 lg:px-6">
                    <!-- <p>Intro here if <i>required</i></p> -->
                    <p>All the latest global Weather & NatCat events reported here, as they happen.</p>
                </div>
                <div class="w-full lg:w-1/2 px-4 lg:px-6 flex items-end" >
                    <div class="w-full flex lg:justify-end">
                        <button #changeCategory class="w-full max-w-xs border border-gray-300 py-3 px-5 rounded-lg shadow-md bg-white cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between items-center">
                            {{ changeCategoryShow }}
                            <svg xmlns="http://www.w3.org/2000/svg" class="arrow-button-change ml-3 fill-current stroke-current" viewBox="0 0 12 12"><g stroke-width="1"><polyline points="0.5 4.5 6 8.5 11.5 4.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline></g></svg>
                        </button>
                    </div>
                </div>
                <div class="w-full px-4 lg:px-6">
                    <div class="mb-4 flex flex-wrap -mx-3">
                        <div class="w-full md:w-1/2 px-3 flex items-stretch mb-3"   *ngFor="let data of news; let last = last;">
                            <div class="rounded-lg w-full border boder-gray-100 p-4 mt-4 bg-white block">
                                <app-news-single-widget [data]="data" [isLast]="last"></app-news-single-widget>

                            </div>
                        </div>
                    </div>

                <!-- <div class="flex justify-center w-full">
                    <ul class="pager flex items-center justify-center">

                        <li class="activeprevious mr-3">
                            <a class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                                <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                            </a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 opacity-75 cursor-default">1</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">2</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">3</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">4</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">5</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">6</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">7</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">8</a>
                        </li>
                        <li class="activenext ml-3">
                            <a class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                            </a>
                        </li>
                    </ul>
                </div> -->
                </div>


            </div>


        </div>
    </div>



