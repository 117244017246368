<div [className]="isLast ? 'flex flex-wrap sm:flex-no-wrap h-full -mx-2' : 'flex flex-wrap sm:flex-no-wrap h-full -mx-2 mb-4'">
    <div class="w-full sm:w-auto xl:w-2/5 sm:flex-grow-0 flex items-stretch w-auto px-2">
        <img src="{{ data.thumbnail }}" class="rounded w-full h-full object-center object-cover image-thumbnail" />
    </div>
    <div class="w-full sm:w-auto xl:w-3/5 sm:flex-grow px-2 py-2 flex items-center">
        <div class="w-full">
            <div *ngIf="data.category">
                <a routerLink="{{ data.category.link }}" class="link-category" *ngIf="data.category.link && data.category.title">
                    <span class="text-sm">{{ data.category.title }}</span>
                </a>
                <span class="text-sm" *ngIf="!data.category.link && data.category.title">{{ data.category.title }}</span>
            </div>
            <div class="block">
                <span class="block font-semibold text-md"><a href="{{data.link}}" (click)="clickednews(data)" target="_blank">{{ data.title }}</a></span>
                <span class="block excerpt-text" [innerHTML]="data.excerpt"></span>
            </div>
        </div>


    </div>
</div>
