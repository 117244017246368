<div *ngIf="userdetails.termsAndCond" class="max-height-container pt-1 pb-2 lg:pt-12 lg:pb-10 px-4 lg:px-0 sticky lg:min-h-screen flex flex-wrap justify-between lg:flex-col top-0">

    <div class="w-auto lg:w-full flex lg:block items-center">
        <a routerLink="/dashboard">
            <!-- <img class="w-auto mx-auto"
                src="assets/images/metswift-logo-new.svg"
                srcset="assets/images/metswift-logo.png 1x, assets/images/metswift-logo@2x.png 2x"> -->
                <img class="w-auto mx-auto pr-3" style="width: 184px;"
                src="assets/images/metswift-horz-WHT.png">
        </a>
    </div>
    <div class="lg:hidden flex items-center ml-auto w-auto">
        <button (click)="onClickAccount()" #accountButtonSm class="w-10 h-10 rounded-full bg-primarylighter mr-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mx-auto" viewBox="0 0 15.832 18.938">
                <g transform="translate(-1.5 -3.535)">
                    <path d="M2,24.416H2A7.416,7.416,0,0,1,9.416,17h0a7.416,7.416,0,0,1,7.416,7.416h0"
                        transform="translate(0 -2.443)" fill="none" stroke="#fff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path>
                    <circle cx="4" cy="4" r="4" transform="translate(5 4.035)" fill="none" stroke="#fff"
                        stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1">
                    </circle>
                </g>
            </svg>
        </button>
        <button (click)="onClickMenuButton($event)" #menuButton [class.active]="menuOpened" class="hamburger w-10 h-10 flex items-center bg-primarylighter rounded-full" type="button">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>        
    </div>


    <app-menu-sidebar #toggleMenu class="block w-full lg:mb-auto" (toggledMenu)="onToggledMenue($event)" [fixedClass]="'mt-4 lg:mt-24 mb-3 lg:mb-0 hidden lg:block'"></app-menu-sidebar>


    <div *ngIf="name" class="w-full lg:w-auto block text-white lg:mt-32 lg:mx-6 relative z-10">
        <button (click)="onClickAccount()" #accountButtonLg class="button-account" [class.opened]="accountMenuOpened">
            <div class="w-full flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-3">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                <span class="unT">{{ name }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" [class.opened]="accountMenuOpened"
                    class="ml-4 w-3 h-3 fill-current stroke-current" viewBox="0 0 16 16">
                    <g stroke-width="1">
                        <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                            points="15.5,10.5 8,5.5 0.5,10.5 "></polyline>
                    </g>
                </svg>                  
            </div>
        </button>
        <div [class.opened]="accountMenuOpened" #accountArea class="account-menu overflow-hidden">
            <!--<div *ngIf="isImage" class="relative avatar-img-container">
                <img [src]="image" class="avatar-img" />-->
                <!-- <div class="absolute -mb-1 bottom-0 left-0 right-0 flex justify-center">
                    <button class="mx-2 bg-white rounded-full w-5 h-5">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="w-2 h-2 mx-auto fill-current stroke-current text-primary" viewBox="0 0 12 12">
                            <g>
                                <path
                                    d="M4.707,8.707l6-6a1,1,0,0,0,0-1.414l-1-1a1,1,0,0,0-1.414,0l-6,6a1,1,0,0,0-.188.26L1.293,9.707,4.447,8.9A1,1,0,0,0,4.707,8.707Z">
                                </path>
                                <path d="M11.5,11H.5a.5.5,0,0,0,0,1h11a.5.5,0,0,0,0-1Z"></path>
                            </g>
                        </svg>
                    </button>
                    <button class="mx-2 bg-white rounded-full w-5 h-5">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="w-2 h-2 mx-auto fill-current stroke-current text-primary" viewBox="0 0 12 12">
                            <g>
                                <path
                                    d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z">
                                </path>
                            </g>
                        </svg>
                    </button>
                </div> -->
            <!--</div>-->
            <ul>
                <li class="block relative overflow-hidden">
                    <a routerLink="/my-account" (click)="closeProfile()" class="block px-4 py-3 text-sm text-center font-light">My account</a>
                </li>
                <li class="block relative overflow-hidden">
                    <a href="#" (click)="onClickSignOut($event)"
                        class="block px-4 py-3 text-sm text-center font-light">Sign Out</a>
                </li>

            </ul>
        </div>
    </div>

    <!--<app-menu-sidebar #toggleMenu class="block w-full" (toggledMenu)="onToggledMenue($event)" [fixedClass]="'mt-4 lg:mt-16 mb-3 lg:mb-0 hidden lg:block'"></app-menu-sidebar>-->

<!-- Added TL080724-->
    <div class="hidden lg:block mx-6 pl-6 pt-6 relative z-10"> 
        <a routerLink="/termandcondition" class="w-full text-sm text-left opacity-75 hover:opacity-100">
            <span class="block text-white">                                 
                Terms and conditions
            </span>
        </a>
    </div>
<!-- END Added TL080724-->
    
</div>