import { Component, OnInit } from '@angular/core';
import { LoaderService } from './../../../services/loader.service';
import { ConnectionService } from './../../../services/connection.service';
import { DashboardService } from './../../../services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { removeAllListeners } from 'process';

@Component({
  selector: 'app-latest-news-widget',
  templateUrl: './latest-news-widget.component.html',
  styleUrls: ['./latest-news-widget.component.scss']
})
export class LatestNewsWidgetComponent implements OnInit {
    news: any = [
        {
            category: {
                link: '/news/category/earthquakes',
                title: 'Earthquakes',
            },
            thumbnail: 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iJMRWBqJe7WA/v1/-1x-1.jpg',
            title: 'M 1.2 - 19km S of Trona, CA',
            excerpt: '<p>Time  2020-06-11 11:11:35 UTC. 2020-06-11 11:11:35 UTC at epicenter.</p><p>Location 19.223°N 155.419°W   Depth 31.51 km (19.58 min)</p>'
        },
        {
            category: {
                link: '/news/category/earthquakes',
                title: 'Earthquakes',
            },
            thumbnail: 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iJMRWBqJe7WA/v1/-1x-1.jpg',
            title: 'M 1.2 - 19km S of Trona, CA',
            excerpt: '<p>Time  2020-06-11 11:11:35 UTC. 2020-06-11 11:11:35 UTC at epicenter.</p><p>Location 19.223°N 155.419°W   Depth 31.51 km (19.58 min)</p>'
        },
        {
            category: {
                link: '/news/category/earthquakes',
                title: 'Earthquakes',
            },
            thumbnail: 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iJMRWBqJe7WA/v1/-1x-1.jpg',
            title: 'M 1.2 - 19km S of Trona, CA',
            excerpt: '<p>Time  2020-06-11 11:11:35 UTC. 2020-06-11 11:11:35 UTC at epicenter.</p><p>Location 19.223°N 155.419°W   Depth 31.51 km (19.58 min)</p>'
        },
    ];
    destroy$: Subject<any> = new Subject();

    constructor(
        private loader:LoaderService,
        private connectionService:ConnectionService,
        private dashboardService:DashboardService
    ) { 

    }

    ngOnInit(): void {
        this.getNews();
    }

    getNews() {
        if (navigator.onLine == true) {
            this.loader.display(true);
            this.dashboardService.getNews({ "id": [] })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.loader.display(false);
                    let successData = data;
                    if ( successData && successData.news ) {
                        let len = successData.news.length;
                        for ( let i=0;i<len;i++ ) {
                            if ( i < 3  ) {
                                // this.news[i] = successData.news[i];
                                this.news[i].category.title = successData.news[i].category;
                                this.news[i].title = successData.news[i].title;
                                this.news[i].link = successData.news[i].link;
                                if ( this.news[i].category.title == 'Storms' ) {
                                    this.news[i].thumbnail = '/assets/images/hurricane.png';
                                    successData.news[i].description = successData.news[i].description.replace(/<dl[^>]*>/g,'').replace(/<\/dl>/g,' ');
                                    successData.news[i].description = successData.news[i].description.replace(/<dt[^>]*>/g,'').replace(/<\/dt>/g,' ');
                                    successData.news[i].description = successData.news[i].description.replace(/<dd[^>]*>/g,'').replace(/<\/dd>/g,' ');
                                    this.news[i].excerpt = successData.news[i].description.substring(0, 100);
                                } else {
                                    if(parseFloat(successData.news[i].title.split(' ')[1]) < 4){
                                        continue;
                                      }
                                    if(i==1){
                                        let dataval=1;
                                        //successData.news[i].title = 'M 7.0 - 4 km E of Pāhala, Hawaii'
                                    } else{
                                        let dataval=2;
                                    }
                                    if(parseFloat(successData.news[i].title.split(' ')[1]) >= 9){
                                        this.news[i].thumbnail = '/assets/images/m9.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 8 && parseFloat(successData.news[i].title.split(' ')[1]) < 9 ){
                                        this.news[i].thumbnail = '/assets/images/m8.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 7 && parseFloat(successData.news[i].title.split(' ')[1]) < 8){
                                        this.news[i].thumbnail = '/assets/images/m7.png';
                                      } else if(parseFloat(successData.news[i].title.split(' ')[1]) >= 6 && parseFloat(successData.news[i].title.split(' ')[1]) < 7){
                                        this.news[i].thumbnail = '/assets/images/m6.png';
                                      } else {
                                        this.news[i].thumbnail = '/assets/images/m5.png';
                                      }
                                    //this.news[i].thumbnail = 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iJMRWBqJe7WA/v1/-1x-1.jpg';
                                    successData.news[i].description = successData.news[i].description.replace(/<dl[^>]*>/g,'').replace(/<\/dl>/g,' ');
                                    successData.news[i].description = successData.news[i].description.replace(/<dt[^>]*>/g,'').replace(/<\/dt>/g,' ');
                                    successData.news[i].description = successData.news[i].description.replace(/<dd[^>]*>/g,'').replace(/<\/dd>/g,' ');
                                    let newsContentArr = successData.news[i].description.split('epicenter');
                                    newsContentArr[0] = '<p>'+newsContentArr[0]+'epicenter.</p>';
                                    newsContentArr[1] = '<p>'+newsContentArr[1]+'</p>';
                                    successData.news[i].description = newsContentArr[0]+newsContentArr[1];
                                    successData.news[i].description = successData.news[i].description.replace('Depth','<br>Depth');
                                    this.news[i].excerpt = successData.news[i].description;
                                }
                            }
                        }
                    }
                },
                error => {
                    this.loader.display(false);
                }
            )
        } else {
            this.connectionService.display(true);
            this.loader.display(false);
        }
    }

}
