import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-news-single-widget',
  templateUrl: './news-single-widget.component.html',
  styleUrls: ['./news-single-widget.component.scss']
})
export class NewsSingleWidgetComponent implements OnInit {
    @Input() data: any;
    @Input() isLast: any;
    constructor(private LS:LogoutService,private loaderService:LoaderService,private router: Router,) { }

    ngOnInit(): void {
    }

    clickednews(data){
      console.log(data);
      this.LS.logOut({"title":"News Detail - "+data.title,"link":"#/news-detail"})
			  .subscribe(
			  data => {
					this.loaderService.display(false);
				 },
			  error => {
				this.router.navigate(['/login']);
				this.loaderService.display(false);
			  }
			  );
    }

}
